import { Composition } from '@accor/ace-ui-core';

export default class BookingEngineCompositionV2 extends Composition {
  constructor(componentHost) {
    super(componentHost);
  }

  initialize() {
    super.initialize();
    this.isCoreBookingEngineV2 = document.querySelector('.core-booking-engine-v2');
    if (this.isCoreBookingEngineV2) {
      this.compositionHooks();
      this.bindCompositionInputEventExtended();
    }
  }

  compositionHooks() {
    this.compositionInput = this.componentHost.querySelector(
      `.${Composition.CLASS_NAMESPACE} .input`
    );
    this.compositionDropdown = this.componentHost.querySelector(
      `.${Composition.PARENT_NAMESPACE}__dropdown`
    );
    this.compositionsCountersButtons = this.componentHost.querySelectorAll(
      '.ace-counter .ace-counter__buttons button'
    );
  }

  /** @inheritDoc */
  openCompositionDropdownHandler() {
    this.compositionDropdown.classList.add('show');
    this.componentHost.closest(`.${Composition.CLASS_NAMESPACE}`).classList.add('open');
    this.compositionInput.focus();
    this.compositionInput.setAttribute('aria-expanded', true);
    this.compositionDropdown.setAttribute('aria-hidden', false);
    if (this.isCoreBookingEngineV2 && window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
      this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--header').style.display = '';
      this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--footer').style.display = '';
      document.querySelector('body').style.height = 0;
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('html').style.overflow = 'hidden';
      setTimeout(() => {
        this.compositionDropdown.scrollTo(0, 0);
      }, 100);
    }
  }
  /**
   * close composition dropdown
   */
  closeCompositionDropdownHanlder() {
    if (this.compositionDropdown.classList.contains('show')) {
      this.compositionDropdown.classList.remove('show');
      this.componentHost.closest(`.${Composition.CLASS_NAMESPACE}`).classList.remove('open');
      this.compositionInput.focus();
      this.compositionInput.setAttribute('aria-expanded', false);
      this.compositionDropdown.setAttribute('aria-hidden', true);
      if (this.isCoreBookingEngineV2 && window.innerWidth < CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.lg) {
        this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--header').style.display = 'none';
        this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--footer').style.display = 'none';
        this.compositionInput.blur();
        document.querySelector('body').style.height = '';
        document.querySelector('body').style.overflow = '';
        document.querySelector('html').style.overflow = '';
      }
    }
  }

  bindCompositionInputEventExtended() {
    this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--header .close')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closeCompositionDropdownHanlder();
    });

    this.compositionDropdown.querySelector('.ace-core-booking-engine__dropdown--footer .validate')?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      this.closeCompositionDropdownHanlder();
    });
  }
}


CoreJS.BaseComponent.registerComponent(
  Composition.CLASS_NAMESPACE,
  BookingEngineCompositionV2,
  true
);
