/**
 * @author Keerthana / Gunasundari
 * @class Billboard
 * @version 1.0
 * @inheritDoc
 */
export default class Billboard extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'ace-billboard-component';
  /** @inheritDoc */
  constructor(componentHost) {
    // Constructor function that takes the componentHost parameter
    super(componentHost);
    // Call the constructor of the parent class (CoreJS.BaseComponent)
  }

  /** @inheritDoc */
  // Initialize function
  initialize() {
    const billboard = this.componentHost.querySelector(
      '.ace-billboard__fullwidth'
    );
    this.fullWidthBtn = this.componentHost?.querySelector('.ace-billboard__fullwidth__button .cmp-button.cmp-button--primary.btn-inverse');
    super.initialize();
    this.setBillboardClickListener();
    CoreJS.ResponsiveUtils.toogleComponent(billboard.parentElement);
    const billboardVal = document.querySelector('.ace-billboard__fullwidth');
    if (billboardVal) {
      const footerVal = document.querySelector('footer');
      if (footerVal) {
        footerVal.classList.add('margin-topzero');
      }
    }
    this.layerElements = document.querySelector('[data-cmp-datalayer]');
    this.dataLayers = this.layerElements?.getAttribute('data-cmp-datalayer');
    this.datalayerValues = JSON.parse(this.dataLayers);
    this.PageName = this.datalayerValues.pageName.split('::');
    if (this.PageName[1] == 'all signature') {
      this.latamTracking();
    }
  }

  latamTracking() {
    this.fullWidthBtn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
      const datasEvent = {
        event: 'GA4event',
        eventName: 'cta_enroll',
        event_data: {
          pagename: this.datalayerValues.pageName,
          enroll_context: 'footer',
          cta_name: 'discover the program'
        }
      };
      window.dataLayer.push({
        'event_data': null
      });
      TagManager.trackEvent(datasEvent);
    }
    );
  }


  /**
     * set Event listener for the GA event
     */
  setBillboardClickListener() {
    const layerElement = document.querySelector('[data-cmp-datalayer]');
    const pageName = layerElement?.getAttribute('data-layer-page');

    // Check if pagename is 'subscription-page'
    if (pageName === 'subscription-page') {
      this.componentHost.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        // Push data to datalayer
        window.dataLayer.push({
          event: 'GAevent',
          eventCategory: 'loyalty program',
          eventAction: 'all signature- footer - click on cta',
          eventLabel: 'become a member'
        });
      });
    }
  }
}

// Register the Billboard component with the CoreJS.BaseComponent
CoreJS.BaseComponent.registerComponent(Billboard.CLASS_NAMESPACE, Billboard);
