export default class Chip extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = 'callout-container';
  /** @inheritDoc */
  // Constructor function that takes the componentHost parameter
  constructor(componentHost) {
    // Call the constructor of the parent class (BaseComponent)
    super(componentHost);
  }
  /** @inheritDoc */
  // Initialize Function
  initialize() {
    const btns = this.componentHost?.querySelectorAll('.ace-chip__list .ace-chip--button');

    // Chip related scripts should go inside this condition as component register class is global
    if (this.componentHost.querySelector('.ace-chip')) {
      btns.forEach((btn) => btn?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
        if (!btn?.classList?.contains('active')) {
          btn?.setAttribute('aria-pressed', 'true');
        } else {
          btn?.setAttribute('aria-pressed', 'false');
        }
        btn.classList.toggle('active');
        this.doFilter();
      }));
      this.chipSlider();
      this.doFilter();
    }
  }

  doFilter() {
    // Choose an operation
    const categories = [...this.componentHost?.querySelectorAll('.ace-chip--button.active')].map((el) => el.dataset.filterTabId);

    // Apply the filter
    const items = this.componentHost?.querySelectorAll('.ace-callout-component');
    let show;
    items?.forEach((item) => {
      if (categories.length === 0) {
        show = true;
      } else {
        // Union: Only one of the categories needs to exist
        show = false;
        for (const k in categories) {
          if (item?.dataset?.filters?.indexOf(categories[k]) > -1) {
            show = true;
            break;
          }
        }
      }
      if (!show) {
        item?.parentElement?.classList?.add('filter-li-hide');
      } else {
        item?.parentElement?.classList?.remove('filter-li-hide');
      }
    });

    // For chips with filter
    if (this.componentHost.querySelector('.ace-chip') && this.componentHost?.classList?.contains('callout-container-api')) {
      if (this.componentHost?.querySelector('.ace-loyalty__inner .ace-guest-viewmore')) {
        this.guestViewmore = this.componentHost?.querySelector('.ace-loyalty__inner .ace-guest-viewmore');
        this.viewMoreStep = parseInt(this.guestViewmore?.getAttribute('data-step'));
        this.seeMoreButtonVariable = this.guestViewmore?.querySelector('.cmp-button__text')?.innerText?.trim();
        if (categories.length !== 0) {
          // fetch all callouts for selected chips calling api
          this.filterViewMoreWithChips(items);

          // Eventlistner on view more only when chip is selected
          this.guestViewmore?.addEventListener(CoreJS.DomEventConstants.CLICK, () => {
            this.viewMoreWithChipsClick();
          });
          // Make screen reader speak
          const totalFilteredItems = this.componentHost.querySelectorAll('.ace-callout-section li:not(.filter-li-hide)')?.length;
          this.screenReaderSpeak(totalFilteredItems);
        } else if (categories.length === 0) {
          // Roll back to initial condition when no chips selected, do not display any callout which was not loaded by load more.
          // show the load more button again
          this.componentHost?.querySelector('.ace-loyalty__inner .ace-guest-viewmore')?.classList.remove('hidden');
          let flagIndex;
          items?.forEach((item, index ) => {
            if (item?.getAttribute('lazy-flag')) {
              flagIndex = index;
            }
            if (index >= flagIndex) {
              item?.parentElement?.setAttribute('hidden', 'true');
            }
          });
          // Make screen reader speak
          const totalFilteredItems = this.componentHost.querySelectorAll('.ace-callout-section li:not([hidden])')?.length;
          this.screenReaderSpeak(totalFilteredItems);
        }
      } else {
        // Make screen reader speak
        const totalFilteredItems = this.componentHost.querySelectorAll('.ace-callout-section li:not(.filter-li-hide)')?.length;
        this.screenReaderSpeak(totalFilteredItems);
      }
    }
  }

  chipSlider() {
    const numberofcomponents = document?.querySelectorAll('.callout-container');

    numberofcomponents?.forEach((item) => {
      const tabsBox = item?.querySelector('.ace-chip .ace-chip__list');
      const arrowButtons = item?.querySelectorAll('.ace-chip button.icon');

      let scrollWidth; let screenWidthAdjuster;
      if (window.screen.width > CoreJS.ResponsiveConstants.GRID_BREAKPOINTS.md) {
        screenWidthAdjuster = 90;
      } else {
        screenWidthAdjuster = 50;
      }

      if (tabsBox) {
        if (tabsBox.scrollWidth < tabsBox.parentNode.clientWidth - screenWidthAdjuster) {
          tabsBox.parentElement.querySelectorAll('button span.rightArrow')[0].parentNode.style.display = 'none';
        }
        arrowButtons?.forEach((icon) => {
          const leftarrowIcon = icon?.querySelector('.leftArrow');
          if (leftarrowIcon) {
            leftarrowIcon.parentElement.style.display = 'none';
          }

          const handleIcons = (scrollVal) => {
            const currentTabbox = icon.parentElement.querySelectorAll('.ace-chip__list')[0];
            const leftIconOfCurrentContainer = icon.parentElement.querySelectorAll('button span.leftArrow')[0];
            const rightIconOfCurrentContainer = icon.parentElement.querySelectorAll('button span.rightArrow')[0];

            const maxScrollableWidth = currentTabbox.scrollWidth - currentTabbox.clientWidth;
            leftIconOfCurrentContainer.parentElement.style.display = scrollVal <= 0 ? 'none' : 'flex';
            rightIconOfCurrentContainer.parentNode.style.display = maxScrollableWidth - scrollVal <= 1 ? 'none' : 'flex';
          };
          icon?.addEventListener('click', () => {
            // if clicked icon is left, reduce 350 from tabsBox scrollLeft else add
            scrollWidth = icon.parentElement.querySelectorAll('.ace-chip__list')[0].scrollLeft += icon?.querySelector('span')?.classList?.contains('leftArrow') ? -340 : 340;
            handleIcons(scrollWidth);
          });
        });
      } else {
        if (item.querySelector('.ace-chip__list button.icon .rightArrow')) {
          item.querySelector('.ace-chip__list button.icon .rightArrow').parentElement.style.display = 'none';
        }
        if (item.querySelector('.ace-chip__list button.icon .leftArrow')) {
          item.querySelector('.ace-chip__list button.icon .leftArrow').parentElement.style.display = 'none';
        }
      }
    });
  }

  /**
  * Lazy load callouts for selected chips by calling api returning path
  * on click of chips
  * @private
  * @param {Object} items All available callouts to show
  */
  filterViewMoreWithChips(items) {
    let v2ImageFlag = false;
    const dataProductList = [];
    const dataCalloutList = [];
    this.filteredCallout = [];

    // filter out chip callouts in array
    items?.forEach((item) => {
      // Display callouts for selected chips
      if (!item?.parentElement?.classList?.contains('filter-li-hide')) {
        this.filteredCallout?.push(item);
      }
      item?.removeAttribute('lazy-flag-chips');
    });

    // Lazy load manipulation on filtered callouts
    this.filteredCallout?.forEach((item, index) => {
      if (index < this.viewMoreStep) {
        // If response fetched from api do not consider again for api call as image already available
        if (!item?.getAttribute('isLoaded')) {
          dataProductList.push(item?.getAttribute('data-product-id'));
          item?.setAttribute('isLoaded', 'true');
          dataCalloutList.push(item);
        } else {
          item?.parentElement?.removeAttribute('hidden');
        }
      } else {
        item.parentElement.setAttribute('hidden', true);
        if (index === this.viewMoreStep) {
          item?.setAttribute('lazy-flag-chips', true);
        }
      }
      if (item?.querySelector('.ace-image-v2')) {
        v2ImageFlag = true;
      }
    });
    // Show hide view more button based on filtered callout length
    if (this.filteredCallout?.length > this.viewMoreStep) {
      this.guestViewmore?.classList.remove('hidden');
    } else {
      this.guestViewmore?.classList.add('hidden');
    }

    if (dataProductList?.length > 0) {
      this.lazyLoadSelectedChips(dataProductList, dataCalloutList, v2ImageFlag);
    }
  }

  /**
  * On click of view more
  */
  viewMoreWithChipsClick() {
    // Do lazy load manipulation only when chip selected
    if (this.componentHost?.querySelector('.ace-chip .ace-chip--button.active')) {
      this.flagIndex = '';
      let v2ImageFlag = false;
      const dataProductList = [];
      const dataCalloutList = [];
      this.filteredCallout?.forEach((item, index) => {
        if (item?.getAttribute('lazy-flag-chips')) {
          this.flagIndex = index;
          // check if v2 image
          if (item?.querySelector('.ace-image-v2')) {
            v2ImageFlag = true;
          }
        }
        if (index >= this.flagIndex && index < this.flagIndex + this.viewMoreStep) {
          if (item?.getAttribute('data-product-id')) {
            if (!item?.getAttribute('isLoaded')) {
              dataProductList.push(item?.getAttribute('data-product-id'));
              dataCalloutList.push(item);
            } else {
              item?.parentElement?.removeAttribute('hidden');
            }
          }
        }
      });
      if (dataProductList?.length > 0) {
        this.lazyLoadSelectedChips(dataProductList, dataCalloutList, v2ImageFlag, true);
      } else {
        this.guestViewmore.classList.add('hidden');
        this.guestViewmore?.classList?.remove('loader');
        this.guestViewmore.querySelector('.cmp-button__text')?.classList?.remove('icon-loader');
        this.guestViewmore.querySelector('.cmp-button__text').innerText = this.seeMoreButtonVariable;
      }
    }
  }

  /**
  * Lazy load Callout images when chip selected
  * @private
  * @param {Array} dataProductList Callouts to be lazy loaded
  * @param {BoolArrayean} dataCalloutList Array containing product id of callouts to be loaded
  * @param {Boolean} v2ImageFlag if img is v2
  * @param {Boolean} isViewMore if triggered by view more click
  */
  lazyLoadSelectedChips(dataProductList, dataCalloutList, v2ImageFlag, isViewMore = false) {
    // If product list available to fetch call api
    if (dataProductList?.length > 0) {
      const { posHandler } = document?.body?.dataset || {};
      const brandSite = JSON.parse(posHandler)?.siteCode;
      const imageRatios = this.componentHost.querySelector('.ace-callout-section')?.getAttribute('data-image-ratios');
      const columns = this.componentHost.querySelector('.ace-callout-section')?.getAttribute('data-columns') ?
        `&noOfColumns=${this.componentHost.querySelector('.ace-callout-section')?.getAttribute('data-columns')}` : '';
      let url = `/a/bin/productImage?hotelIds=${dataProductList?.toString()}`;
      if (v2ImageFlag) {
        url = `/a/bin/productImage?hotelIds=${dataProductList?.toString()}&imageVersion=v2&brand=${brandSite}&ratios=${imageRatios}${columns}`;
      }

      new CoreJS.XHRPromisifiedRequest(url, CoreJS.XHRPromisifiedRequest.HTTP_METHOD_GET)
        .setRequestHeaders({ 'Content-Type': 'application/json' })
        .executeRequest()
        .then((response) => {
          const dataResponse = JSON.parse(response.responseText);
          const responseKey = Object.keys(dataResponse);
          const responseValue = Object.values(dataResponse);
          dataCalloutList?.forEach((item) => {
            responseKey?.forEach((key, count) => {
              if (item?.getAttribute('data-product-id') === key) {
                if (v2ImageFlag) {
                  const pictureSource = item?.querySelectorAll('picture source');
                  pictureSource[0]?.setAttribute('srcset', responseValue[count]?.S);
                  pictureSource[1]?.setAttribute('srcset', responseValue[count]?.M);
                  pictureSource[2]?.setAttribute('srcset', responseValue[count]?.L);
                  pictureSource[3]?.setAttribute('srcset', responseValue[count]?.XL);
                  pictureSource[4]?.setAttribute('srcset', responseValue[count]?.XXL);
                  item?.querySelector('img')?.setAttribute('src', responseValue[count]?.default);
                } else {
                  item?.querySelector('img')?.setAttribute('data-src', responseValue[count]?.default);
                }
                item?.parentElement?.removeAttribute('hidden');
              }
            });
            if (isViewMore) {
              item?.removeAttribute('lazy-flag-chips');
            }
          });

          if (isViewMore) {
            if (this.filteredCallout?.length > this.flagIndex + this.viewMoreStep) {
              this.filteredCallout[this.flagIndex + this.viewMoreStep]?.setAttribute('lazy-flag-chips', true);
            } else {
              this.guestViewmore.classList.add('hidden');
            }
            this.guestViewmore?.classList?.remove('loader');
            this.guestViewmore.querySelector('.cmp-button__text')?.classList?.remove('icon-loader');
            this.guestViewmore.querySelector('.cmp-button__text').innerText = this.seeMoreButtonVariable;
          }
        })
        .catch((error) => {
          console.error(error);
          if (isViewMore) {
            this.guestViewmore?.classList?.remove('loader');
            this.guestViewmore.querySelector('.cmp-button__text')?.classList?.remove('icon-loader');
            this.guestViewmore.querySelector('.cmp-button__text').innerText = this.seeMoreButtonVariable;
          }
        });
    }
  }

  /**
  * Make screen reader speak callout number
  * @private
  * @param {Number} totalFilteredItems Nomber of callouts visible
  */
  screenReaderSpeak(totalFilteredItems) {
    this.srEventText = 'No items displayed';
    if (totalFilteredItems > 0) {
      this.srEventText = `${totalFilteredItems} items displayed`;
    }
    this.componentHost?.setAttribute('data-sr-event-text', this.srEventText);
    CoreJS.ScreenReaderHelper.makeScreeReaderSpeak(this.componentHost, CoreJS.ScreenReaderHelper.ARIA_LIVE_ASSERTIVE);
  }
}

CoreJS.BaseComponent.registerComponent(Chip.CLASS_NAMESPACE, Chip);
