// Stylesheets
import './theme.scss';
import './resources/images/favicon-16x16.png';
import './resources/images/favicon-32x32.png';
import './resources/images/apple-touch-icon.png';
import './resources/images/android-chrome-192x192.png';
import './resources/images/android-chrome-512x512.png';
import './resources/images/mstile-150x150.png';

// ACE Library JS
import "@accor/ace-ui-core";
import './components/content/billboard/v1/js/billboard';
import './components/content/highlight/v1/js/highlight';
import './components/common/callouts/v1/callout/js/callout';
import './components/common/callouts/v1/callout-container/js/callout-container';
import './components/common/table-container/v1/js/table-container';
import './components/common/faq/v1/js/faq';
import './components/content/calltoaction/v1/js/calltoaction'
import './components/content/billboard/v1/js/billboard_full-grid';
import './components/common/homepage/v2/js/homepage-v2';
import './components/content/calltoaction/v1/js/calltoaction';
import './components/content/billboard/v1/js/billboard_full-grid';
import './components/common/heading-hero/v1/js/heading-hero';
import './components/common/core-booking-engine/v1/destination-dropdown/js/destination-dropdown';
import './components/common/core-booking-engine/v2/js/core-booking-engine';
import './components/common/core-booking-engine/v2/composition/js/composition';
import './components/common/brand-corner/v1/js/brand-corner';
import './components/common/lp-activity-event/v1/js/lp-activity-event';
import './components/content/paragraph/v1/js/paragraph';
import './components/content/card-editorial-slideshow/v1/js/card-editorial';
import './components/common/basic-page-template/v1/js/basic-page-template';;
// import hub
import './components/common/hub/v1/js/hub';
// import listV2
import './components/content/list/v2/js/list-v2';
// import section
import './components/common/section/v1/js/section';
// cta-enrollement
import './components/common/cta-enrollment/v1/js/cta-enrollment';
// skip-the-clean-template
import './components/common/skip-the-clean-template/v1/js/skip-the-clean-template';

document.dispatchEvent(new Event(CoreJS.CustomDomEventConstants.THEME_LOADED_EVENT));
