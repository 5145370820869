// ACE Common components
import SubNavContainer from './src/components/common/ace-navigation/v1/sub-nav-container/js/sub-nav-container';
import BookNow from './src/components/common/book-now/v1/js/book-now';
import Callout from './src/components/common/callouts/v1/callout/js/callout';
import CalloutContainer from './src/components/common/callouts/v1/callout-container/js/callout-container';
import Composition from './src/components/common/core-booking-engine/v1/composition/js/composition';
import DestinationDropdown from './src/components/common/core-booking-engine/v1/destination-dropdown/js/destination-dropdown';
import DatePicker from './src/components/common/core-booking-engine/v1/date-picker/js/date-picker';
import CoreBookingEngine from './src/components/common/core-booking-engine/v1/js/core-booking-engine';
import Countdown from './src/components/common/countdown/v1/js/countdown';
import CountryFlag from './src/components/common/country-flags/v1/js/country-flags';
import DataLayer from './src/components/common/datalayer/v1/js/datalayer';
import DataLayerHandler from './src/components/common/datalayer/v1/js/datalayer-handler';
import GaEvents from './src/components/common/datalayer/v1/js/ga-events';
import Faq from './src/components/common/faq/v1/js/faq';
import Heading from './src/components/common/heading/v1/js/heading';
import HeadingHero from './src/components/common/heading-hero/v1/js/heading-hero';
import LongStayForm from './src/components/common/long-stay-form/v1/js/long-stay-form';
import Loyalty from './src/components/common/loyalty/v1/js/loyalty';
import LoyaltyInfo from './src/components/common/loyalty/v1/js/loyalty-info';
import Message from './src/components/common/message/v1/js/message';
import MyAccount from './src/components/common/my-account/v1/js/my-account';
import NavBar from './src/components/common/navigation/v1/js/navbar';
import NavigationToogle from './src/components/common/navigation/v1/js/navigation-toogle';
import CurrencySelector from './src/components/common/navigation-header/v1/currencyselector/currecncyselector';
import GeoLocation from './src/components/common/navigation-header/v1/geolocation/geolocation';
import HeaderNavigation from './src/components/common/navigation-header/v1/js/navigation-header';
import QuickAccess from './src/components/common/quick-access/v1/js/quick-access';
import QuickAccessV2 from './src/components/common/quick-access/v2/js/quick-access-v2';
import SharedFooter from './src/components/common/shared-footer/v1/js/shared-footer';
import SharedNavigation from './src/components/common/shared-navigation/v1/js/shared-navigation';
import SocialMedia from './src/components/common/social-media/v1/js/social-media';
import SubscriptionBlock from './src/components/common/subscription-block/v1/js/subscription-block';
import SwitcherTable from './src/components/common/switcher-table/v1/js/switcher-table';
import TableContainer from './src/components/common/table-container/v1/js/table-container';
import VideoPlayer from './src/components/common/video-player/v1/js/video-player';
import WeclomeMessage from './src/components/common/welcome-message/v1/js/welcome-message';
import Chip from './src/components/common/chip/v1/js/chip';
import ScrollLazyLoadComponent from './src/components/common/lazyload-scroll/v1/js/lazyloading';
import Newsletter from './src/components/common/newsletter/v1/js/newsletter';
import CampaignBanner from './src/components/common/campaign-banner/v1/js/campaign-banner';
import BackgroundSection from './src/components/common/background-section/v1/js/background-section';

// ACE Content components
import Billboard from './src/components/content/billboard/v1/js/billboard';
import CardComponent from './src/components/content/card-component/v1/js/card-component';
import CardEditorialSlideshow from './src/components/content/card-editorial-slideshow/v1/js/card-editorial-slideshow';
import Carousel from './src/components/content/carousel/v1/js/carousel';
import CarouselV2 from './src/components/content/carousel/v2/js/carousel-v2';
import IconList from './src/components/content/icon-list/v1/js/icon-list';
import List from './src/components/content/list/v1/js/list';
import Modal from './src/components/content/modal/v1/js/modal';
import NavFooterList from './src/components/content/nav-footer-list/v1/js/nav-footer-list';
import Separator from './src/components/content/separator/v1/js/separator';
import SlideShow from './src/components/content/slideshow/v1/js/slideshow';
import SlideShowCarousel from './src/components/content/slideshow-carousel/v1/js/slideshow-carousel';
import ListV2 from './src/components/content/list/v2/js/list-v2';
import ListGrid from './src/components/content/list-grid/v1/js/list-grid';
import DownlaodApp from './src/components/content/download-app/v1/js/download-app';
import LoyaltyBanner from './src/components/content/loyalty-banner/v1/js/loyalty-banner';
// ACE Technical components
import LanguageSelector from './src/components/technical/language-selector/v1/language-selector/js/language-selector';

// ACE Form components
import CustomDropdown from './src/components/form/dropdown/v1/js/dropdown';
// Common lib
import BasePageTemplate from './src/base/lib/components/v1/structure/base-page-template/v1/js/base-page-template';

import TagManager from '@accor/core-services/dist/gtm/index';

export {
  DataLayer,
  SharedFooter,
  GaEvents,
  DataLayerHandler,
  BookNow,
  Composition,
  DestinationDropdown,
  CoreBookingEngine,
  Message,
  QuickAccess,
  MyAccount,
  CalloutContainer,
  Callout,
  Separator,
  Carousel,
  LanguageSelector,
  SwitcherTable,
  CountryFlag,
  Heading,
  DatePicker,
  VideoPlayer,
  NavBar,
  NavigationToogle,
  SlideShow,
  CardEditorialSlideshow,
  IconList,
  List,
  NavFooterList,
  Faq,
  Billboard,
  Modal,
  CustomDropdown,
  SharedNavigation,
  BasePageTemplate,
  Loyalty,
  SubscriptionBlock,
  Countdown,
  CardComponent,
  HeadingHero,
  SocialMedia,
  SlideShowCarousel,
  LongStayForm,
  TagManager,
  SubNavContainer,
  CurrencySelector,
  GeoLocation,
  HeaderNavigation,
  CarouselV2,
  TableContainer,
  LoyaltyInfo,
  QuickAccessV2,
  WeclomeMessage,
  Chip,
  ScrollLazyLoadComponent,
  ListV2,
  ListGrid,
  Newsletter,
  CampaignBanner,
  BackgroundSection,
  DownlaodApp,
  LoyaltyBanner
};
